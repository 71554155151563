<template>
    <div>
        <loading v-show="pageLoading"/>
        <div v-show="!pageLoading">
            <div v-show="formProcess=='smsSend'">
                <ValidationObserver ref="formSmsSend">
                    <ValidationProvider name="mobile_number" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('mobile_number')">
                            <b-form-input v-model="mobile_tel"
                                          v-mask="'(+49)-##-########'"
                                          placeholder="(+49)-##-########"
                                          :state="errors[0] ? false :null"
                                          v-phone-number
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <div class="d-flex justify-content-center">
                        <b-button variant="primary"
                                  @click="smsSend"
                                  :disabled="formLoading">
                            {{ $t('update') }}
                        </b-button>
                    </div>
                </ValidationObserver>
            </div>
            <div v-show="formProcess=='smsVerify'">
                <b-form-group :label="$t('mobile_number')">
                    <div class="label-as-input">{{ mobile_tel }}</div>
                </b-form-group>
                <div class="mb-3">
                    <ValidationObserver ref="formSmsVerify">
                        <ValidationProvider name="sms_code" rules="required" v-slot="{valid, errors}">
                            <sms-input v-model="smsCode"
                                       :timerFrom="smsTimer"
                                       @timerExpired="timerExpired"
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                </div>
                <b-row>
                    <b-col>
                        <b-button type="button"
                                  variant="primary"
                                  size="lg"
                                  block
                                  @click="smsVerify"
                                  :disabled="smsTimer==0"
                        >
                            {{ $t('verify') }}
                        </b-button>
                    </b-col>
                    <b-col>
                        <b-button type="submit"
                                  variant="outline-secondary"
                                  size="lg"
                                  block
                                  @click="smsSend()"
                                  :disabled="smsTimer>0"
                        >
                            {{ $t('send_again') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
    // Components
    import Loading from '@/components/elements/Loading.vue';
    import SmsInput from '@/components/elements/SmsInput.vue';

    // Services
    import PersonService from '@/services/PersonService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            Loading,
            SmsInput,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            mobile_telephone: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                // Page
                pageLoading: false,

                // Form
                formLoading: false,
                formProcess: null,

                // Form
                mobile_tel: null,
                smsTimer: 0,
                smsCode: ''
            }
        },
        async created() {
            this.mobile_tel = this.mobile_telephone;
            this.formProcess = 'smsSend';
        },
        mounted() {
            this.smsTimer = 10
        },
        methods: {
            async smsSend() {
                const isValid = await this.$refs.formSmsSend.validate();
                if (isValid) {
                    let formData = {
                        mobile_tel: this.mobile_tel
                    }
                    PersonService.sendSmsCode(formData)
                                 .then(response => {
                                     let data = response.data.data;
                                     this.smsTimer = data.expires_in
                                 })
                                 .then(() => {
                                     this.formProcess = 'smsVerify'
                                 })
                                 .catch(e => {
                                     this.showErrors(e, this.$refs.formSmsSend);
                                 })

                }
            },
            timerExpired(event) {
                this.smsTimer = 0;
            },

            async smsVerify() {
                const isValid = await this.$refs.formSmsVerify.validate();
                if (isValid) {
                    let formData = {
                        sms_code: this.smsCode
                    }
                    this.formLoading = true;
                    PersonService.verifySmsCode(formData)
                                 .then(response => {
                                     let message = response.data.message
                                     this.$toast.success(this.$t('api.' + message));
                                     this.$emit('mobileNumberUpdated', this.mobile_tel)
                                 })
                                 .catch(e => {
                                     this.showErrors(e, this.$refs.addressForm)
                                 })
                                 .finally(() => {
                                     this.formLoading = false;
                                 })
                }
            }
        }
    }
</script>
